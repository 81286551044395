import { Col, Flex, Form, message, Modal, Row, Space, Spin, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Logo } from '../assets'
import { LandingDraggableImageContent } from '../content/LandingDraggableImageContent'
import { LandingFormButtonContent } from '../content/LandingFormButtonContent'
import { LandingFormContent } from '../content/LandingFormContent'
import { LandingImageContent } from '../content/LandingImageContent'
import { Errors } from '../contexts/ErrorContext'
import { LandingModel } from '../model/LandingModel'
import { ConsultingService } from '../services/ConsultingService'
import { LandingItemType } from '../type/LandingItemType'

export const Product: FunctionComponent = () => {
    let { id } = useParams<{ id: string }>()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [notFound, setNotFound] = useState<boolean>(false)
    const [items, setItems] = useState<Array<LandingModel.ILandingItemModel>>([])
    const [script, setScript] = useState<string>()

    const [form] = Form.useForm()

    const detail = async (landingId: string) => {
        setLoading(true)
        const response = await ConsultingService.consulting(landingId)
        if (response.status === 200) {
            const item = response.data.item as { items: Array<any>, scripts?: Array<LandingModel.ILandingScriptModel>, script?: string }

            setItems(item.items ?? [])
            setScript(item.script)

            if (item.script) {
                const regex = /AW-\d+/
                const match = item.script.match(regex)
                if (match && match[0]) {
                    const id = match[0]
                    gtag('config', id)
                }
            }

//             if (item.script) {
//                 const regex = /'send_to':\s*'([^/]+)\//;
//                 const match = item.script.match(regex)
//                 if (match && match[1]) {
//                     const id = match[1]
//                     gtag('config', id);
//                 }
//             }
        } else {
            setNotFound(true)
            Errors.AjaxError(response.data)
        }
        setLoading(false)
    }

    const handleCreate = () => {
        form.validateFields()
            .then(async (values) => {
                Modal.confirm({
                    title: "문의를 등록하시겠습니까?",
                    okText: "확인",
                    cancelText: "취소",
                    onOk: async () => {
                        if (script) {
                            try {
                                eval(script)
                            } catch (error) {
                                console.error("Error executing script:", error, script)
                            }
                        }

                        setLoading(true)
                        const data = {
                            ...values,
                            연락처: values['연락처'] ? `010${values['연락처']}` : '',
                            url: `${window.location.href}${window.location.search}`
                        }

                        const response = await ConsultingService.create(id!!, data)
                        if (response.status === 200) {
                            message.success('문의가 등록되었습니다.', 2, () => {
                                window.location.reload()
                            })
                        } else {
                            Errors.AjaxError(response.data)
                        }
                        setLoading(false)
                    }
                })
            })
            .catch((e) => {
                console.log('error', e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (id) {
            detail(id)
        }
    }, [id])

    return (
        <>
            <Flex justify={'center'}>
                {/* <Button
                    style={{ margin: 30 }}
                    onClick={() => {
                        if (script) {
                            try {
                                eval(script)
                            } catch (error) {
                                console.error("Error executing script:", error, script)
                            }
                        }
                    }}
                >
                    {'이벤트 테스트'}
                </Button> */}
                <Spin
                    spinning={isLoading}
                    style={{
                        marginTop: isLoading ? 150 : 0,
                    }}
                    size='large'
                ></Spin>
            </Flex>
            {notFound && (
                <Row style={{ height: window.innerHeight }} justify={'center'} align={'middle'}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Space direction='vertical' size={'large'}>
                            <img
                                src={Logo}
                                alt='logo' />
                            <Typography.Title level={3}>
                                {`종료된 이벤트 입니다,`} <br />
                                {`다음에 다시 참여해 주세요!`} <br />
                            </Typography.Title>
                        </Space>
                    </Col>
                </Row>
            )}
            <Row gutter={[16, 16]} justify={'center'} style={{ paddingInline: 16, }}>
                <Col {...{ xs: 24, sm: 20, md: 16, lg: 14, xl: 12, xxl: 10 }}>
                    <Row style={{ marginBottom: 16, }}>
                        {items?.map((r, idx: number) => {
                            const key = `${r?.type}_${idx}`

                            return (
                                <Col
                                    key={key}
                                    span={24}
                                >
                                    {(() => {
                                        switch (r?.type) {
                                            case LandingItemType.image:
                                                return (
                                                    <LandingImageContent item={r} />
                                                )
                                            case LandingItemType.draggable:
                                                return (
                                                    <LandingDraggableImageContent item={r} />
                                                )
                                            case LandingItemType.form:
                                                return (
                                                    <LandingFormContent
                                                        item={r}
                                                        form={form} />
                                                )
                                            case LandingItemType.button:
                                                return (
                                                    <LandingFormButtonContent
                                                        item={r}
                                                        onSubmit={handleCreate}
                                                    />
                                                )
                                            default:
                                                return <></>
                                        }
                                    })()}
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    )
}