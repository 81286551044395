import { Col, Row, Space, Typography } from 'antd'
import { FunctionComponent, useEffect } from 'react'
import { Logo } from '../assets'

export const Home: FunctionComponent = () => {
    useEffect(() => {
    }, [])

    return (
        <Row style={{ height: window.innerHeight }} justify={'center'} align={'middle'}>
            <Col span={24} style={{ textAlign: 'center' }}>
                <Space direction='vertical' size={'large'}>
                    <img
                        alt='logo' 
                        width={'100%'}
                        src={Logo}
                        />
                    <Typography.Title level={3}>
                        {`어떤 증상이 있으신가요?`} <br />
                        {`저희에게 알려주세요!`} <br />
                    </Typography.Title>
                    <Typography.Link href='mailto:skysysky@naver.com'>
                        {`skysysky@naver.com`}
                    </Typography.Link>
                </Space>
            </Col>
        </Row>
    )
}