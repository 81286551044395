export enum LandingDbItemType {
    typography = 'typography',
    phone = 'phone',
    gender = 'gender',
    username = 'username',
    age = 'age',
    date = 'date',
    datetime = 'datetime',
    price = 'price',
    select = 'select',
    check = 'check',
}